<script lang="ts">
  import { project, orgOperators } from "$lib/services/store";
  import { translations } from "$lib/data/translations";
  import Group from "$lib/admin/components/Group.svelte";
  import Input from "$lib/admin/components/Input.svelte";
  import TipTapEditor from "$lib/admin/components/content/TipTapEditor/TipTapEditor.svelte";
  import Intro from "$lib/admin/components/blocks/sections/Intro.svelte";
  import ArticleAd from "$lib/admin/components/blocks/helpers/ArticleAd.svelte";
  import { createEventDispatcher, onMount } from "svelte";
  import supabase from "$lib/services/db";
  import type { OperatorFeedback } from "@/src/types/components/operators/OperatorFeedback";
  import RecentChanges from "$lib/admin/components/blocks/blocks/RecentChanges.svelte";
  import type { Operator } from "@/src/types/components/operators/Operator";

  interface IntroReviewProps {
    bonusType: string;
    showCTA: boolean;
    methodology: boolean;
    recentChanges: boolean;
    recentChangesList: string[];
    operatorId: number;
    header: string;
    buttonAnchor: string;
    buttonUrl: string;
    tocData: {
      tag: string;
      title: string;
    };
    introCTAType: string;
    buttonType: string;
    content: string;
    methodologyText: string;
    crm?: boolean;
    crmText?: string;
  }

  export let props: IntroReviewProps;
  export let adminView = false;

  let selectedTab = "facts";
  let reviewOperator: Operator | undefined;
  let feedbacks: OperatorFeedback[] = [];

  const dispatch = createEventDispatcher();
  const lang = $project.settings.lang ?? "en";

  props.bonusType = "single-table";
  props.showCTA = true;
  props.methodology = props.methodology || false;
  props.recentChanges = props.recentChanges || false;
  props.recentChangesList = props.recentChangesList || [];
  props.crm = props.crm || false;
  props.crm = props.crm || false;
  props.crmText = props.crmText || "";

  $: {
    reviewOperator = $orgOperators?.find((x) => x.id === props.operatorId);
    props.header = reviewOperator?.name;
    props.buttonAnchor =
      translations["To"][lang] + " " + reviewOperator?.name ?? "Casino Review";
    props.buttonUrl = `/${$project.settings.redirectUrl}/${reviewOperator?.name_lower}`;
    props.tocData = {
      tag: `${translations["Facts"][lang].toLowerCase()}`,
      title: `${translations["Facts about"][lang]} ${reviewOperator?.name}`,
    };
    if (
      reviewOperator &&
      (reviewOperator.rating[$project.key][$project.settings.market][
        "closed"
      ] ||
        !reviewOperator.rating[$project.key][$project.settings.market][
          "license"
        ])
    ) {
      props.showCTA = true;
      props.introCTAType = "internal";
      props.buttonType = "transparent";
      props.buttonAnchor = "Se Liknande Casinon";
      props.buttonUrl = "/stangt";
    }
    if (
      reviewOperator &&
      reviewOperator.rating[$project.key][$project.settings.market]["upcoming"]
    ) {
      props.showCTA = true;
      props.introCTAType = "internal";
      props.buttonType = "transparent";
      props.buttonAnchor = "Se Liknande Casinon";
      props.buttonUrl = "/kommer-snart";
    }
  }

  async function getOperatorFeedback(id: number) {
    const { data, error: feebackErr } = await supabase
      .from("operator_feedback")
      .select("*")
      .eq("operator_id", id);

    feedbacks = data as OperatorFeedback[];
    return feedbacks;
  }

  onMount(async () => {
    if (reviewOperator?.id) {
      await getOperatorFeedback(reviewOperator?.id);
    }
  });
</script>

{#if adminView}
  <Group colspan="4-8" addClass="form-item">
    <Input
      label="Operator"
      type="select"
      bind:value={props.operatorId}
      on:change={() => dispatch("keyup")}
    >
      <option value="">~not set~</option>
      {#each $orgOperators as operator}
        <option value={operator.id}
          >{operator.name || "Couldn't find operator name"}</option
        >
      {/each}
    </Input>
  </Group>
  <Group addClass="form-item">
    <TipTapEditor bind:text={props.content} on:keyup />
  </Group>
  <Group colspan="1-11">
    <Input
      type="checkbox"
      label="Methodology"
      bind:checked={props.methodology}
    />
  </Group>
  {#if props.methodology}
    <Group addClass="form-item">
      <TipTapEditor bind:text={props.methodologyText} on:keyup />
    </Group>
  {/if}
  <Group colspan="1-11">
    <Input
      type="checkbox"
      label="Recent changes"
      bind:checked={props.recentChanges}
    />
  </Group>
  {#if props.recentChanges}
    <RecentChanges bind:listItems={props.recentChangesList} />
  {/if}
  <Group colspan="1-11">
    <Input type="checkbox" label="CRM" bind:checked={props.crm} />
  </Group>
  {#if props.crm}
    <Group colspan="4-8" addClass="form-item">
      <Input label="CRM Text" type="text" bind:value={props.crmText} />
    </Group>
  {/if}
{:else}
  <Intro
    {props}
    {adminView}
    operator={reviewOperator}
    methodology={props.methodology}
    methodologyText={props.methodologyText}
    crmText={props.crmText}
    recentChanges={props.recentChanges}
    recentChangesList={props.recentChangesList}
  />
  <ArticleAd />
{/if}
