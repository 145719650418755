<script>
	import Group from "$lib/admin/components/Group.svelte";
	import Input from "$lib/admin/components/Input.svelte";
	import Cards from "$lib/admin/components/Cards.svelte";
	import Modal from "$lib/main/components/Modal.svelte";
	import Manage from "$lib/admin/components/Manage.svelte";
	import Button from "$lib/main/components/Button.svelte";
  import TipTapEditor from "$lib/admin/components/content/TipTapEditor/TipTapEditor.svelte";
	import { createEventDispatcher } from "svelte";


	// props.tabs: Object of {string, Array[Array[]]}
	//             where key is name of tab, and nested array is table content.
	export let props = null;
	export let adminView = false;

	let message = null;
	let toggleAddTab = false;
	let toggleEditCell = false;
  let toggleEditTabName = false;
  let toggleConfirmDeleteTab = false;

	let selectedCell = [0, 0];
	let selectedTab = null;
	let pendingTabName = "";

	if (!props.tabs) {
		props.tabs = {};
	} else {
		selectedTab = Object.keys(props.tabs)[0];
	}

	let dispatch = createEventDispatcher();

	const addRow = () => {
		props.tabs[selectedTab].push([]);
		normalizeCells();
	};
	const removeRow = () => {
		props.tabs[selectedTab] = props.tabs[selectedTab].slice(
			0,
			props.tabs[selectedTab].length - 1
		);
		normalizeCells();
	};
	const addColumn = () => {
		props.tabs[selectedTab][0].push(null);
		normalizeCells();
	};
	const removeColumn = () => {
		props.tabs[selectedTab][0] = props.tabs[selectedTab][0].slice(
			0,
			props.tabs[selectedTab][0].length - 1
		);
		normalizeCells();
	};
	const normalizeCells = () => {
		if (!props.tabs[selectedTab].length) {
			return;
		}
		const header = props.tabs[selectedTab][0];
		for (let i = 1; i < props.tabs[selectedTab].length; i++) {
			let row = props.tabs[selectedTab][i];
			while (row.length !== header.length) {
				if (row.length < header.length) row.push(null);
				if (row.length > header.length) row = row.slice(0, row.length - 1);
			}
			props.tabs[selectedTab][i] = row;
		}
		dispatch("keyup");
	};
</script>

{#if adminView}
  <Group colspan="3" addClass="form-item">
    <Button
      addClass="secondary"
      icon="plus-square"
      on:click={() => (toggleAddTab = !toggleAddTab)}>New Tab</Button>
    {#if selectedTab || selectedTab == ""}
      <Button
        icon="edit"
        on:click={() => toggleEditTabName = true}
      >
       Edit Tab Name
      </Button>
      <Button
        addClass="primary"
        icon="minus-square"
        on:click={() => toggleConfirmDeleteTab = true}>Delete Tab</Button>
    {/if}
  </Group>
  <Group colspan="4" addClass="form-item">
    {#each Object.keys(props.tabs) as tabName}
      <Button
        addClass={tabName === selectedTab ? "tertiary" : ""}
        on:click={() => {
          selectedTab = tabName;
        }}>{tabName}</Button
      >
    {/each}
  </Group>

	{#if props.tabs[selectedTab]}
    <Group colspan="6" addClass="form-item">
      <h4>Columns</h4>
      <Button onlyIcon icon="plus-square" on:click={addColumn} />
      <Button onlyIcon icon="minus-square" on:click={removeColumn} />
      <h4>Rows</h4>
      <Button onlyIcon icon="plus-square" on:click={addRow} />
      <Button onlyIcon icon="minus-square" on:click={removeRow} />
    </Group>
		<table class="admin-tabbed-tables">
			<tbody>
				{#each props.tabs[selectedTab] as row, i}
					<tr>
						{#each row as cell, j}
							<!-- svelte-ignore a11y-click-events-have-key-events -->
							<td on:click={() => {
                selectedCell = [i, j];
                toggleEditCell = !toggleEditCell;
              }}>{@html cell}</td>
						{/each}
					</tr>
				{/each}
			</tbody>
		</table>
	{/if}

  <!-- Edit a cell value -->
  <Modal bind:toggleModal={toggleEditCell}>
    <svelte:fragment slot="header">Edit Cell</svelte:fragment>
    <TipTapEditor
      bind:text={props.tabs[selectedTab][selectedCell[0]][selectedCell[1]]}
      placeholder="Cell value"
      on:keyup
    />
    <hr />
    <Button
      addClass="secondary"
      on:click={() => (toggleEditCell = !toggleEditCell)}>Finish</Button
    >
  </Modal>

  <!-- Create a new table tab -->
  <Modal bind:toggleModal={toggleAddTab}>
    <svelte:fragment slot="header">New table tab</svelte:fragment>
    <Input bind:value={pendingTabName} label="Tab Name" placeholder="Tab name" />
    <hr />
    <Manage {message} />
    <Button
      addClass="secondary"
      on:click={() => {
        if (Object.keys(props.tabs).filter((k) => k === pendingTabName).length) {
          message = {
            success: false,
            display: `Tab named "${pendingTabName}" already exists.`,
          };
          return;
        }
        selectedTab = pendingTabName;
        props.tabs[selectedTab] = [[]];
        pendingTabName = "";
        toggleAddTab = !toggleAddTab;
        dispatch("keyup");
      }}
    >Save</Button>
  </Modal>

  <!-- Edit tab name -->
  <Modal bind:toggleModal={toggleEditTabName}>
    <svelte:fragment slot="header">New table tab</svelte:fragment>
    <Input bind:value={pendingTabName} label="New Tab Name" placeholder={selectedTab} />
    <hr />
    <Manage {message} />
    <Button
      addClass="secondary"
      on:click={() => {
        if (Object.keys(props.tabs).filter((k) => k === pendingTabName).length) {
          message = {
            success: false,
            display: `Tab named "${pendingTabName}" already exists.`,
          };
          return;
        }
        props.tabs[pendingTabName] = props.tabs[selectedTab]
        delete props.tabs[selectedTab]
        selectedTab = pendingTabName;
        pendingTabName = "";
        toggleEditTabName = false;
        dispatch("keyup");
      }}
    >Save</Button>
  </Modal>

  <!-- Confirm delete selected tab -->
  <Modal bind:toggleModal={toggleConfirmDeleteTab}>
    <svelte:fragment slot="header">Confirm Delete Tab: "{selectedTab}"</svelte:fragment>
    <Button
      addClass="primary"
      on:click={() => {
        delete props.tabs[selectedTab];
        if (Object.keys(props.tabs).length) {
          selectedTab = Object.keys(props.tabs)[0];
        } else {
          selectedTab = null;
        }
        toggleConfirmDeleteTab = false;
        dispatch("keyup");
      }}
    >
      Confirm Delete
    </Button>
    <Button addClass="secondary" on:click={() => toggleConfirmDeleteTab = false}>
      Cancel
    </Button>
  </Modal>

{:else if !adminView && props}
  <div class="block block-tabbed-tables">
    <div class="container">
      <div class="grid">
        <div class="g-col-12">
          <div class="tab-section">
            <ul class="tabs">
              {#each Object.keys(props.tabs) ?? [] as tabName}
                <li
                  class={tabName === selectedTab ? "active" : ""}>
                  <button
                    on:click={() => {
                      selectedTab = tabName;
                    }}>{ tabName }</button>
                </li>
              {/each}
            </ul>

            <table>
              <tbody>
                {#each props.tabs[selectedTab] ?? [] as row, i}
                  <tr>
                    {#each row as cell, j}
                      <td>
                        {@html cell}
                      </td>
                    {/each}
                  </tr>
                {/each}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
{/if}

<style>
  .admin-tabbed-tables tr td {
    background: #444;
    white-space: normal;
  }
  :global(.admin-tabbed-tables tr td p) {
    margin: 0;
  }
  .admin-tabbed-tables tr td:hover {
    background: #333;
    cursor: pointer;
  }
</style>