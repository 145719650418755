<script>
  import Group from "$lib/admin/components/Group.svelte";
  import TipTapEditor from "$lib/admin/components/content/TipTapEditor/TipTapEditor.svelte";

  export let props = null;
  export let adminView = null;
</script>

{#if adminView}
  <Group addClass="form-item">
    <TipTapEditor bind:text={props.content} placeholder="Placeholder content" on:keyup />
  </Group>
{:else}
  <div class="block block-placeholder">
    <div class="container">
      <div class="grid">
        <div class="g-col-12 g-start-1 g-col-md-10 g-start-md-2">
          {#if props.content}
            {@html props.content}
          {:else}
            <p class="empty-content">This is a placeholder component. Use this to remind you of content you want to put here at a later time.</p>
          {/if}
        </div>
      </div>
    </div>
  </div>
{/if}

<style>
  .block-placeholder .container .grid {
    background: var(--highlight-clr);
    background: #feffdd; /* TEMP: */
    padding: 1rem;
    border-radius: var(--bd-rad);
  }
  :global(.block-placeholder .container .grid p) {
    font-size: 0.8125rem;
    font-weight: 200;
    text-align: center;
    font-style: italic;
    margin: 0 0 0.75rem 0;
  }
  :global(.block-placeholder .container .grid .g-col-12 > *:last-of-type) {
    margin: 0;
  }
</style>