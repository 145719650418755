<script>
  import Group from "$lib/admin/components/Group.svelte";
  import Input from "$lib/admin/components/Input.svelte";
  import Button from "$lib/main/components/Button.svelte";
  import TipTapEditor from "$lib/admin/components/content/TipTapEditor/TipTapEditor.svelte";
  import Translate from '$lib/admin/components/blocks/helpers/Translate.svelte';
  import Block from '$lib/admin/components/blocks/helpers/Block.svelte';

  // props.focusPanelType: select - Choose the type of info box content to show
  //  options: basic, resource, facts
  // props.focusPanelContent: suneditor - WYSIWIG for content
  // props.focusPanelBasicType: select
  //  options: default, note, warning
  // props.focusPanelResourceType: select
  //  options: fact, remember, custom
  // props.focusPanelResourceSource: input - A URL for linking to a source (optional)
  // props.focusPanelFacts: array - Holds array of facts

  export let props = null;
  export let adminView = false;

  function add() {
    if(!Array.isArray(props.focusPanelFacts)) props.focusPanelFacts = []
    props.focusPanelFacts.push({description: "", number: ""})
    props = props
  }

  function remove(index) {
    props.focusPanelFacts.splice(index, 1)
    props = props
  }
</script>

{#if adminView}
  <Group addClass="form-item">
    <Group colspan="4-8">
      <Input
        label="Focus panel type"
        type="select"
        bind:value={props.focusPanelType}
        on:change>
        <option value="basic">Basic</option>
        <option value="resource">Resource</option>
        <option value="facts">Facts</option>
      </Input>
    </Group>
    {#if props.focusPanelType == "basic"}
      <Input type="select" bind:value={props.focusPanelBasicType} on:change>
        <option value="default">Default</option>
        <option value="note">Note</option>
        <option value="warning">Warning</option>
      </Input>
    {/if}
    {#if props.focusPanelResourceType == 'custom'}
      <Input
        label="Focus panel title"
        placeholder="Write a title"
        bind:value={props.focusPanelTitle}
        on:keyup />
    {/if}
    {#if (props.focusPanelType == "basic") || (props.focusPanelType == "resource")}
      <!-- <Content {adminView} bind:props /> -->
      <TipTapEditor
          bind:text={props.focusPanelContent}
          on:keyup />
    {/if}
    {#if props.focusPanelType == "resource"}
      <Input type="select" bind:value={props.focusPanelResourceType} on:change>
        <option value="custom">Custom</option>
        <option value="remember">Remember</option>
        <option value="fact">Fact</option>
      </Input>
      <Group colspan="4-8" addClass="form-item">
        <Input bind:value={props.focusPanelResourceSource} label="Source (optional)" placeholder="Link to source (optional)" on:keyup />
      </Group>
    {/if}
    {#if props.focusPanelType == "facts"}
      {#if props.focusPanelFacts}
        {#each props.focusPanelFacts as fact, index}
          <Group colspan="2-6-3-auto" addClass="form-item narrow fs-xs">
            <Input
              label={"Fact " + (index + 1)}
              type="text"
              placeholder="Description"
              bind:value={fact.description}
              on:keyup
              />
            <Input
              type="text"
              placeholder="Number"
              bind:value={fact.number}
              on:keyup
              />
            <Button addClass="secondary" onlyIcon icon="trash-2" on:click={() => remove(index)} />
          </Group>
        {/each}
      {/if}
      <Button addClass="primary" icon="plus-square" on:click={() => add()}>Add fact</Button>
    {/if}
  </Group>
{:else}
  <Block
    blockClass={"block-focus-panel block-focus-panel-" + props.focusPanelType}
    gridColumn="g-col-12 g-start-1 g-col-md-10 g-start-md-2"
    {props}>
    {#if props.focusPanelType == "facts"}
      <div class="focus-panel">
        {#if props.focusPanelFacts}
          {#each props.focusPanelFacts as fact}
            <div class="fact-panel">
              <span>{fact.number}</span>
              <span>{fact.description}</span>
            </div>
          {/each}
        {/if}
      </div>
    {:else if props.focusPanelType == "resource"}
      <div class="focus-panel">
        {#if props.focusPanelResourceType == 'custom'}
          {#if props.focusPanelTitle}
            <span>{@html props.focusPanelTitle}</span>
          {/if}
          <div class="resource remember">
            {@html props.focusPanelContent}
            {#if props.focusPanelResourceSource}
              <a href={ props.focusPanelResourceSource } target="_blank" rel="nofollow noreferrer"><Translate text="Source" /></a>
            {/if}
          </div>
        {:else if props.focusPanelResourceType == 'remember'}
          <span><Translate text="Remember that..." /></span>
          <div class="resource remember">
            {@html props.focusPanelContent}
            {#if props.focusPanelResourceSource}
              <a href={ props.focusPanelResourceSource } target="_blank" rel="nofollow noreferrer"><Translate text="Source" /></a>
            {/if}
          </div>
        {:else}
          <span><Translate text="Did you know?" /></span>
          <div class="resource">
            {@html props.focusPanelContent}
            {#if props.focusPanelResourceSource}
              <a href={ props.focusPanelResourceSource } target="_blank" rel="nofollow noreferrer"><Translate text="Source" /></a>
            {/if}
          </div>
        {/if}
      </div>
    {:else}
      <div class={"focus-panel " + props.focusPanelBasicType}>
        {@html props.focusPanelContent}
      </div>
    {/if}
  </Block>
{/if}