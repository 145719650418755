<script lang="ts">
  import type { PropAboutTournamentProvider } from "@/src/types/tournaments/Tournament";
  import Group from "../../../Group.svelte";
  import Input from "../../../Input.svelte";
  import TipTapEditor from "$lib/admin/components/content/TipTapEditor/TipTapEditor.svelte";
  import BlockBackgroundSelector from "../../helpers/BlockBackgroundSelector.svelte";
  import Translate from "../../helpers/Translate.svelte";
  import Block from "../../helpers/Block.svelte";

  export let adminView = false;

  export let props: PropAboutTournamentProvider = {};
</script>

{#if adminView}
  <BlockBackgroundSelector bind:value={props.bgColor} on:change={(e) => (props.bgColor = e.detail.bgColor)} />
  <Group>
    <Input placeholder="Provider" on:keyup bind:value={props.title} />
    <h6 style="margin: 0;">Description</h6>
    <TipTapEditor on:keyup bind:text={props.description} />
  </Group>
{:else}
  <div style="background: {props.bgColor};">
    <div class="about-provider-container container">
      <h3 class="title">
        <Translate text="About" />
        {props.title}
      </h3>
      {#if props && props.description}
        {@html props.description}
      {/if}
      <div class="buttons-wrapper">
        <a href="https://www.pragmaticplay.com/" about="_blank">
          <button class="button-cta">
            <Translate text="More about" />
            {props.title}
          </button>
        </a>
        <a href="/spelutvecklare">
          <button class="button-secondary">
            <Translate text="Similar Providers" />
          </button>
        </a>
      </div>
    </div>
  </div>
{/if}

<style>
  .about-provider-container {
    padding: 24px 1rem;
    display: flex;
    flex-flow: column;
    margin: 30px auto 30px;

    @media only screen and (min-width: 576px) {
      padding: 24px 0;
    }

    @media only screen and (min-width: 768px) {
      margin-top: 10px;
    }
  }
  .title {
    text-align: center;
    margin: 0 0 20px 0;
  }
  .buttons-wrapper {
    display: flex;
    flex-flow: column;
    gap: 16px;
    justify-content: center;

    & button {
      padding: 0 27px;
      color: white;
      white-space: nowrap;
      border-radius: 6px;
      font-size: 16px;
      height: 54px;
      width: 100%;
    }

    @media only screen and (min-width: 768px) {
      flex-flow: row;

      & button {
        width: 360px;
      }
    }
  }
  .button-cta {
    background-color: #cc3129;
    transition: background-color 0.3s;
    border: none;
    &:hover {
      background-color: #a82720;
    }
  }
  .button-secondary {
    border: 1px solid #cc3129;
    background-color: transparent;
  }
</style>
